import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import city from '../assets/images/Cinivu_2.0.8.jpg'
import Creator from '../assets/images/Creator.png'
import Coin from '../assets/images/Coin.png'
import Coffee from '../assets/images/Coffee.png'
import cafeGif from '../assets/images/TCCBanner.gif'
import Launch_Platform from '../assets/images/Launch_Platform.png'
import Merchandise from '../assets/images/TCClogo300.webp'
import MetaConnected from '../assets/images/Meta-connected.png'
import heroSliderData from '../assets/fake-data/data-slider';
import { Helmet } from 'react-helmet-async';
import ComicsCafeStyleWrapper from './ComicsCafe.style';
import Slider from '../components/slider/Slider';
import { APIInfo } from '../config';
import { useEffect, useState } from 'react';
import CreativeGuild from '../components/layouts/cafe/CreativeGuild';
import UpcomingComic from '../components/layouts/cafe/UpcomingComic';


const ComicsCafe = () => {
    const [membershipList, setMembershipList] = useState(null);
    useEffect(async() => {
        const res = await fetch(APIInfo.API_URL+'/story/membership/list/api/');
        setMembershipList(await res.json());
        
    }, []);


    return (
        <ComicsCafeStyleWrapper>
        <div>
            <Helmet>
                    <title>CINIVU 2.0 - Comics Cafe + ComicVerse</title>
               
                    <meta name='description' content="CINIVU 2.0 - Comics Cafe + ComicVerse." />
                       
            </Helmet>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">CINIVU 2.0 Roadmap</h1>
                                <h3 className="mt-4 text-center">Comics Cafe + ComicVerse</h3>
                            </div>
                            
                        </div>
                    </div>
                </div>                    
            </section>
            {/* 
                {membershipList &&
                    <section className='tf-section mb-0 pt-0 mt-0 pb-0'>
                        
                            <Slider data={heroSliderData} membershipList={membershipList}/>
                            
                    </section>
                }
            */}
            <section className='tf-section mb-0 pt-0 pb-5'>
                <div className='row'>

                    <div className='col-12 col-md-5 offset-1 mt-4'>
                        <h3 className='mt-2'>The Idea</h3>
                        <br/>
                        
                        <div className='mt-2 row'>
                            <div className='col-2 pointer'>1. </div>
                            <div className='col-9'>
                            <span>
                            We will be opening our first physical <strong>Comics Cafe</strong> within India. It's your comic book store with International, Domestic, Indie comics collection.
                            </span>
                            </div>
                        </div>
                        <div className='mt-4 row'>
                        <div className='col-2 pointer'>2. </div>
                            <div className='col-9'>
                            <span>
                            Each cafe will be connected to our central cafe in the Metaverse via VR headsets.
                            </span>
                            </div>
                        </div>
                        <div className='mt-4 row'>
                        <div className='col-2 pointer'>3. </div>
                            <div className='col-9'>
                            <span>
                            Community meetups within the physical store as well in the Comicverse. Meet & Greet with the creators in the Metaverse.
                            </span>
                            </div>

                        </div>
                        <div className='mt-4 row'>
                            <div className='col-2 pointer'>4. </div>
                            <div className='col-9'>
                            <span>
                            Entry to the cafe will be free initially. Eventually, you should have any one of our NFT or the <strong>membership pass NFT</strong>.
                            </span>
                            </div>
                        </div>
                        
                    </div>
                    <div className='col-12  col-md-5 mt-4'>
                        <img src={city} />
                    </div>
                </div>
            </section>
            <section className='flat-title-page mt-0 pt-1'>
                <div className='row section-center'>
                    <div className='row sc-card-collection style-2 cafe-pointer-div'>
                        
                            <div className='col-12 col-md-2'>
                                <img src={Coffee}/>
                            </div>
                            <div className='col-12 col-md-9 offset-0'>
                                <p className='sub-heading p-center p-left'><strong className='title'>Comic Book Store + Cafe. </strong>Why not read comics & have coffee. Separate collection of comics for you to read while sipping your coffee.</p>
                            </div>

                        
                    </div>
                </div>
                <div className='row section-center'>
                    <div className='row sc-card-collection style-2 cafe-pointer-div'>
                    <div className='col-12 col-md-4 text-center'>
                        <a href='https://thecomics.cafe/' target="_blank"><img src={cafeGif}/></a>
                        <a href='https://thecomics.cafe/' target="_blank">
                        
                        </a>
                    </div>
                    <div className='col-12 col-md-6'>
                        <p className='sub-heading mb-3 p-center p-right'><a href='https://thecomics.cafe/' target="_blank"><strong className='title'>Comics & Merchandising.</strong></a> We sell famous Manga, DC, Marvel comics, printed t-shirts, posters. Separate section for <strong>Indie Comics</strong> along with our comics.</p>
                    </div>

                    <div className='col-12 col-md-2 offset-0 pointer-img-div flex-1'>
                        <p>
                            <a href='https://thecomics.cafe/' target="_blank">
                                <img src={Merchandise}/>
                            </a>
                        </p>    
                    </div>
                    </div>
                </div>
                <div className='row section-center'>
                <div className='row sc-card-collection style-2 cafe-pointer-div'>
                    <div className='col-12 col-md-2'>
                        <img src={MetaConnected}/>
                    </div>
                    <div className='col-12 col-md-9 offset-0'>
                        <p className='sub-heading p-center p-left'><strong className='title'>ComicsVerse. </strong>Every cafe will be connected to our cafe in the Metaverse via VR headsets. Regular meetups with our artists, writers. Upcoming comics sneak peek. We call the experience, <strong><a href="/comicverse/">ComicVerse</a></strong></p>
                    </div>
                    </div>
                </div>
                <div className='row section-center'>
                    <div className='row sc-card-collection style-2 cafe-pointer-div'>
                    <div className='col-12 col-md-10'>
                        <p className='sub-heading mb-3 p-center p-right'><strong className='title'>Comic Launch Platform. </strong>As we open multiple stores across the world. CINIVU will be able <strong>launch and distribute</strong> new comics in the market.</p>
                    </div>

                    <div className='col-12 col-md-2 offset-0 pointer-img-div'>
                        <p><img src={Launch_Platform}/></p>
                    </div>
                    </div>
                </div>
                <div className='row section-center'>
                    <div className='row sc-card-collection style-2 cafe-pointer-div'>
                    <div className='col-12 col-md-2'>
                    <a href="#id-creator-guild"><img src={Creator}/></a>
                    </div>
                    <div className='col-12 col-md-9 offset-0'>
                        <p className='sub-heading p-center p-left'><strong className='title'><a href="#id-creator-guild">Creators Guild.</a> </strong> Maintains the quality of artwork we produce. Artists <strong>compete</strong> for the design. We create posters, nft of the design. Winner gets <strong>royality</strong> of every poster, nft sale.</p>
                    </div>
                    </div>
                </div>
                <div className='row section-center mb-5'>
                    <div className='row sc-card-collection style-2 cafe-pointer-div'>
                    <div className='col-12 col-md-10'>
                        <p className='sub-heading mb-3 p-center p-right'><strong className='title'>Cinivu Coin. </strong>We accept transactions in <strong>INR, Solana, and CINIVU Coin</strong> across all our platforms. Great offers and discounts available on chosing CINIVU Coin as the mode of payment. Cinivu Coin drives the company forward. We believe the value of coin to go up as we add more value to the comic driven economy.</p>
                    </div>

                    <div className='col-12 col-md-2  pointer-img-div'>
                        <img src={Coin}/>
                    </div>
                    </div>
                </div>
            </section>
            <CreativeGuild/>
            <UpcomingComic/>
            <Footer />
        </div>

        </ComicsCafeStyleWrapper>
    );
}

export default ComicsCafe;
