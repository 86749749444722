import React , { useState , useMemo, useEffect} from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {useParams} from 'react-router-dom';
import idl from '../idl.json';
import * as anchor from '@project-serum/anchor';
import {Connection, PublicKey, clusterApiUrl, SystemProgram, Transaction, LAMPORTS_PER_SOL, sendAndConfirmTransaction} from '@solana/web3.js';
import { Program, AnchorProvider, utils, web3, BN } from '@project-serum/anchor';
import { NATIVE_MINT, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID, getAssociatedTokenAddress, getAccount, getOrCreateAssociatedTokenAccount, getMint, createSyncNativeInstruction, createAssociatedTokenAccountInstruction } from "@solana/spl-token";
import { APIInfo } from '../config';
import { Button} from 'react-bootstrap';
import {Buffer} from 'buffer';
import {useWallet } from '@solana/wallet-adapter-react'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom';
import walletIcon from '../assets/images/icon/wallet.png'
import settingsIcon from '../assets/images/svg/settings.svg'

window.Buffer = Buffer;

const programID = new PublicKey(idl.metadata.address);
let network;
let provider;
const opts = {
  preflightCommitment: "processed"
}


const ConnectWallet = () => { 

    const [address, setAddress] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
      setAddress(Cookies.get("address"))
  },[address]);


    const disconnect = ()=>{
      Cookies.remove("address")
      Cookies.remove("is_superuser")
      setAddress(Cookies.get("address"))
      
      navigate("/")

    }
  
    const navigateSettings = ()=>{

      navigate('/profile');
      
      //navigate("/")

    }

    const navigateLogin = () => {
      // 👇️ navigate to /
      if(window.location.pathname && (window.location.pathname != "/profile" || window.location.pathname != "/login")){
        navigate('/login?next='+window.location.pathname);
      }
    };

    return (
        <>
            <div className="sc-btn-top mg-r-12" id="site-header">
            {address && window.location.pathname != "/profile" &&
              <Button onClick={navigateSettings} className="wallet-adapter-button-trigger sc-button header-slider style pri-1">
                <img src={settingsIcon} />
                <span>My Account</span>
              </Button>
            }

            {address && window.location.pathname == "/profile" &&
              <Button onClick={disconnect} className="wallet-adapter-button-trigger sc-button header-slider style pri-1">
                <img src={walletIcon} />
                <span>Disconnect</span>
              </Button>
            }
            {!address && 
              <Button onClick={()=>{navigateLogin()}} className="wallet-adapter-button-trigger sc-button header-slider style pri-1">
                <img src={walletIcon} />
                <span>Wallet connect</span>
              </Button>
            }
            </div>
            
        </>
    );
}



export default ConnectWallet;
