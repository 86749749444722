import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import cityImg from '../../../assets/images/TCCcityscape.jpg'
import shape1 from '../../../assets/images/backgroup-secsion/bg-gradient1.png'
import shape2 from '../../../assets/images/backgroup-secsion/bg-gradient2.png'
import shape3 from '../../../assets/images/backgroup-secsion/bg-gradient3.png'
import { APIInfo } from '../../../config';

const UpcomingComic = props => {
    
    
    return (
        <>
        
            
            <section className='tf-section pt-4 pb-1' >
                <div>
                    <div className='row pt-4 '>
                    <div className="col-12 col-xs-12 pr-5 text-center mb-5">
                        <h2 className=''>Upcoming Comic</h2>
                        </div>
                    </div>
                    <div className='tf-section row pt-4 pb-5'>
                    <div className="col-11 col-md-5 col-xs-12 pr-5 mb-5 mt-5 offset-1">
                        <h3 className="heading mb-2 mt-5 section-title">Vishwas</h3>	
                    
                        
                        <p className="">
                            A young and successful detective is famous in the entire district for his uncanny ability to solve any and all cases that come to him. But the secret behind his skills is a bit more sinister than simple intellect. 
                        </p>
                        <p className="">
                            Faced with a mysterious new case, and its distinct connection to his own enigmatic powers, how will the detective fare? Especially when, for the first time in his life, he is the one in the dark?
                        </p>
                        
                        <div className="flat-bt-slider flex style2 mt-4">
                            <Link to={`/comic/vishwas`} className="sc-button header-slider style style-1 fl-button pri-1 far fa-eye fa-2x icon_home">
                            
                                <span>Sneak Peek
                            </span></Link>
                            
                            
                        </div>
                    </div>
                    <div className="col-10 col-md-4 col-xs-12 offset-1">
                        <Link to={`comic/vishwas`}>
                            <picture>
                                <source width="500" height="500" srcset={cityImg} type="image/webp" />
                                <img width="500" height="500" src={cityImg} alt="Story Image"></img>
                            </picture>
                            
                        </Link>
                    </div>  
                    </div>
                </div> 
            </section>
            
        
        </>
    );
}

UpcomingComic.propTypes = {
    
    
}
const SliderItem = props => (
    <div className="flat-title-page">
        <img className="bgr-gradient gradient1" src={shape1} alt="Axies" />
        <img className="bgr-gradient gradient2" src={shape2} alt="Axies" />
        <img className="bgr-gradient gradient3" src={shape3} alt="Axies" />
        <div className="shape item-w-16"></div>
        <div className="shape item-w-22"></div>
        <div className="shape item-w-32"></div>
        <div className="shape item-w-48"></div>
        <div className="shape style2 item-w-51"></div>
        <div className="shape style2 item-w-51 position2"></div>
        <div className="shape item-w-68"></div>
        <div className="overlay"></div>
        <div className="swiper-container mainslider home">
            <div className="swiper-wrapper">
                <div className="swiper-slide">
                    <div className="slider-item">	
                        <div className="themesflat-container ">
                            <div className="wrap-heading flat-slider flex">
                                <div className="content">
                                    <h2 className="heading">{props.item.name}</h2>	
                                    <h1 className="heading mb-style"><span className="">{props.item.title_2}</span>                                          
                                    </h1>
                                    <h1 className="heading"><span className='fill'>{props.item.title_3}</span>{props.item.title_4}</h1>
                                    <p className="sub-heading" dangerouslySetInnerHTML={{ __html: props.item.description }}>
                                    </p>
                                    <div className="flat-bt-slider flex style2">
                                        <Link to="/stories" className="sc-button header-slider style style-1 fl-button pri-1 far fa-eye fa-2x icon_home">
                                        
                                            <span>Explore
                                        </span></Link>
                                        <Link to={`comic//${props.item.slug}`} className="sc-button header-slider style style-1 note fl-button pri-1"><span>Create
                                        </span></Link>
                                        
                                    </div>
                                </div>
                                <div className="image">
                                    <Link to={`comic//${props.item.slug}`}>
                                        
                                        <img className='homepage-image' src={APIInfo.API_URL + props.item.image_url} alt="axies" />
                                    </Link>
                                </div>
                            </div>   
                        </div>					                           
                    </div>
                </div>
            </div>
        </div>        
    </div>
    
)
export default UpcomingComic;
