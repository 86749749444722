import React , { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import 'react-tabs/style/react-tabs.css';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

import { ReactSession } from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import ProfileLoginCheck from '../components/ProfileLoginCheck';
import EditProfileStyleWrapper from './account/EditProfile.style';


const Authors02 = () => {
    
    
    const address = ReactSession.get("address");
    const navigate = useNavigate()
    const [modalShow, setModalShow] = useState(false);


    return (
        <div className='authors-2'>
            <Header />
                <EditProfileStyleWrapper>

                <ProfileLoginCheck />
                </EditProfileStyleWrapper>
                
            
   
            <Footer />
            
            
            
        </div>
    );
}

export default Authors02;
