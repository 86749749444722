const menus = [
    {
        id: 1,
        name: 'Comics',
        links: '/comics/',
        
    },
    {
        id: 2,
        name: 'Comics Cafe',
        links: '/comics-cafe/',
    },
    {
        id: 3,
        name: 'Contact',
        links: '/contact-us/',
    },
    
]

export default menus;