import Activity01 from "./Activity01";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import HelpCenter from "./HelpCenter";
import Authors02 from "./Authors02";
import WalletConnect from "./WalletConnect";
import FAQ from "./FAQ";
import Terms from "./Terms";
import Contact02 from "./Contact02";
import AboutUs from "./AboutUs";


const routes = [
  //{ path: '/', component: <Home02 />},
  //{ path: '/home-02', component: <Home02 />},
  //{ path: '/home-03', component: <Home03 />},
  //{ path: '/home-04', component: <Home04 />},
  //{ path: '/home-05', component: <Home05 />},
  //{ path: '/home-06', component: <Home06 />},
  //{ path: '/home-07', component: <Home07 />},
  //{ path: '/home-08', component: <Home08 />},
  //{ path: '/explore-02', component: <Explore02 />},
  //{ path: '/explore-03', component: <Explore03 />},
  //{ path: '/explore-04', component: <Explore04 />},
  //{ path: '/explore-01', component: <Explore01 />},
  //{ path: '/item-details-02', component: <ItemDetails02 />},
  { path: '/activity', component: <Activity01 />},
  //{ path: '/activity-01', component: <Activity02 />},
  { path: '/blogs/', component: <Blog />},
  { path: '/about-us/', component: <AboutUs />},
  { path: '/terms/', component: <Terms />},
  { path: '/blog/:slug/', component: <BlogDetails />},
  { path: '/help-center', component: <HelpCenter />},
  //{ path: '/authors-01', component: <Authors01 />},
  { path: '/profile', component: <Authors02 />},
  { path: '/wallet-connect', component: <WalletConnect />},
  //{ path: '/account/settings', component: <EditProfile />},
  //{ path: '/create-item', component: <CreateItem />},
  //{ path: '/edit-profile', component: <EditProfile />},
  //{ path: '/ranking', component: <Ranking />},
  //{ path: '/login', component: <Login />},
  //{ path: '/sign-up', component: <SignUp />},
  //{ path: '/no-result', component: <NoResult />},
  { path: '/faq', component: <FAQ />},
  { path: '/contact-us', component: <Contact02 />},
  //{ path: '/contact-us/', component: <Contact02 />},
]

export default routes;