import './App.css';
import {   Routes , Route } from 'react-router-dom';
import routes from './pages/index'
import { HelmetProvider } from 'react-helmet-async';
import React, { Component, lazy, Suspense } from 'react';
import EditProfile from './pages/EditProfile';
import WalletContextProvider from './contexts/WalletContextProvider';
import ComicsCafe from './pages/ComicsCafe';


const Home02 = lazy(() => import('../src/pages/Home02'))
const MintCast = lazy(() => import('../src/pages/cast/MintCast'))
const MintChapter = lazy(() => import('../src/pages/chapter/MintChapter'))
const MintDialog = lazy(() => import('../src/pages/dialog/MintDialog'))
const MintScene = lazy(() => import('../src/pages/scene/MintScene'))
const MintStoryBanner = lazy(() => import('../src/pages/story/MintStoryBanner'))
const AddToWhitelist = lazy(() => import('../src/pages/AddToWhitelist'))
const ChapterDetail = lazy(() => import('../src/pages/ChapterDetail'))
const ListNFT = lazy(() => import('../src/pages/account/ListNFT'))
const ListCast = lazy(() => import('../src/pages/cast/ListCast'))
const LiveAuctions = lazy(() => import('../src/pages/LiveAuctions'))
const HowItWorks = lazy(() => import('../src/pages/HowItWorks'))
const HowItWorks2 = lazy(() => import('../src/pages/HowItWorks2'))
const DetailCast = lazy(() => import('../src/pages/cast/DetailCast'))
const DetailScene = lazy(() => import('../src/pages/ItemDetails01'))
const ComicVerse = lazy(() => import('../src/pages/ComicVerse'))
const StoryDetail = lazy(() => import('../src/pages/StoryDetail'))
const Login = lazy(() => import('../src/pages/Login'))
const Profile = lazy(() => import('../src/pages/Authors02'))
const AddCast = lazy(() => import('../src/pages/cast/AddCast'))
const UploadArtwork = lazy(() => import('../src/pages/artwork/Upload'))
const CreateMembership = lazy(() => import('../src/pages/membership/CreateMembership'))
const SellMembership = lazy(() => import('../src/pages/membership/SellMembership'))



function App() {

    return (
        
        <HelmetProvider>
        <Suspense fallback={"<h1>Loading</h2>"}>
        <Routes >
        <Route path="/" element={<HomeWrapper/>} />
        <Route path="/profile" element={<ProfileWrapper/>} />
        <Route path="/comicverse" element={<ComicVerseWrapper/>} />
        <Route path="/membership/:membership_id/mint/" element={<CreateMembershipWrapper/>} />
        <Route path="/membership/:membership_id/" element={<SellMembershipWrapper/>} />
        <Route path="/how-it-works" element={<HowItWorksWrapper/>} />
        <Route path="/how-it-works2" element={<HowItWorks2Wrapper/>} />
        <Route path="/cast/:address/" element={<DetailCastWrapper/>} />
        <Route path="/chapter/nft/:address/" element={<DetailChapterWrapper/>} />
        <Route path="/scene/:address/" element={<DetailSceneWrapper/>} />
        <Route path="/artwork/upload/" element={<UploadArtworkWrapper/>} />
        <Route path="/comic/:slug/" element={<StoryDetailWrapper/>} />
        <Route path="/comics" element={<ListComicWrapper/>} />
        <Route path="/cast/" element={<ListCastWrapper/>} />
        <Route path="/login" element={<LoginWrapper/>} />
        <Route path="/nfts" element={<ListNFTWrapper/>} />
        <Route path="/account/settings" element={<EditProfileWrapper/>} />
        <Route path="/chapter/:slug/:page" element={<ChapterDetailWrapper/>} />
        <Route path="/comic/mint/cast/:slug/" element={<MintCastWrapper/>} />
        <Route path="/comic/chapter/:slug/mint/" element={<MintChapterWrapper/>} />
        <Route path="/comic/chapter/:slug/:address/" element={<MintChapterWrapper/>} />
        <Route path="/comic/scene/:slug/mint/" element={<MintSceneWrapper/>} />
        <Route path="/comic/:slug/mint/" element={<MintStoryBannerWrapper/>} />
        <Route path="/comic/:slug/cast/add/" element={<AddCastWrapper/>} />
        <Route path="/comic/dialog/:dialog_id/mint/" element={<MintDialogWrapper/>} />
        <Route path="/comic/:slug/:address/" element={<MintStoryBannerWrapper/>} />
        <Route path="/comic/:slug/mint/" element={<ComicWhitelistWrapper/>} />
        <Route path="/comics-cafe/" element={<ComicsCafeWrapper/>} />
            {
            routes.map((data,index) => (
                <Route onUpdate={() => window.scrollTo(0, 0)} exact={true} path={data.path} element={data.component} key={index} />
            ))
            }
      </Routes>
      </Suspense>
      </HelmetProvider>
      
    );
}

function HomeWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <Home02/>
            </Suspense>
        </>
    )
}

function ProfileWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
            <WalletContextProvider>
                <Profile/>
                </WalletContextProvider>
            </Suspense>
        </>
    )
}
function AddCastWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
            <WalletContextProvider>
                <AddCast/>
                </WalletContextProvider>
            </Suspense>
        </>
    )
}
function EditProfileWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <WalletContextProvider>
                <EditProfile/>
                </WalletContextProvider>
            </Suspense>
        </>
    )
}
function LoginWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <WalletContextProvider>
                    <Login/>
                </WalletContextProvider>
            </Suspense>
        </>
    )
}

function CreateMembershipWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <WalletContextProvider>
                    <CreateMembership/>
                </WalletContextProvider>
            </Suspense>
        </>
    )
}


function SellMembershipWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <WalletContextProvider>
                    <SellMembership/>
                </WalletContextProvider>
            </Suspense>
        </>
    )
}
function ComicWhitelistWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <AddToWhitelist/>
            </Suspense>
        </>
    )
}

function ComicsCafeWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <ComicsCafe/>
            </Suspense>
        </>
    )
}

function ComicVerseWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <WalletContextProvider>
                <ComicVerse/>
                </WalletContextProvider>
            </Suspense>
        </>
    )
}
function HowItWorksWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <HowItWorks/>
            </Suspense>
        </>
    )
}
function HowItWorks2Wrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <HowItWorks2/>
            </Suspense>
        </>
    )
}
function DetailCastWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <DetailCast/>
            </Suspense>
        </>
    )
}
function DetailChapterWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <WalletContextProvider>
                <ChapterDetail/>
                </WalletContextProvider>
            </Suspense>
        </>
    )
}
function DetailSceneWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <DetailScene/>
            </Suspense>
        </>
    )
}
function UploadArtworkWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <WalletContextProvider>
                    <UploadArtwork/>
                </WalletContextProvider>
            </Suspense>
        </>
    )
}
function StoryDetailWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <WalletContextProvider>
                <StoryDetail/>
                </WalletContextProvider>
            </Suspense>
        </>
    )
}
function ListComicWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <LiveAuctions/>
            </Suspense>
        </>
    )
}
function ListCastWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <ListCast/>
            </Suspense>
        </>
    )
}
function ListNFTWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <ListNFT/>
            </Suspense>
        </>
    )
}
function ChapterDetailWrapper(){
    return (
        <>
            
            <WalletContextProvider>
                <ChapterDetail/>
            </WalletContextProvider>
            
        </>
    )
}

function MintChapterWrapper(){
    return (
        <>
            <WalletContextProvider>
                
                    <MintChapter/>
            </WalletContextProvider>
        </>
    )
}


function MintCastWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <MintCast/>
            </Suspense>
        </>
    )
}
function MintSceneWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
                <MintScene/>
            </Suspense>
        </>
    )
}
function MintStoryBannerWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
            
                <MintStoryBanner/>
                
            </Suspense>
        </>
    )
}
function MintDialogWrapper(){
    return (
        <>
            <Suspense fallback={"<h1>Loading</h2>"}>
            <WalletContextProvider>
                <MintDialog/>
            </WalletContextProvider>
            </Suspense>
        </>
    )
}

export default App;
