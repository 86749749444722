import React , {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import img1 from '../assets/images/box-item/card-item-10.jpg'
import { APIInfo } from '../config';
import { Helmet } from 'react-helmet-async';


const Activity01 = () => {

    useEffect( ()=>{
        async function fetchData() {
            const res = await fetch(APIInfo.API_URL+"/activity/api/");
            const responseList = await res.json()
            setDataBox(responseList)
        }
        fetchData();
    }
    , []);

    const [dataBox, setDataBox] = useState(
        [
            {
                img: img1,
                title: 'Monica Lucas',
                status: 'started following',
                author: 'Gayle Hicks',
                time: 'At 2:30 PM on 19th June, 2021',
                icon: 'icon-1',
                link: '',
            },
            
        ]);
    const [dataFilter] = useState(
        [
            {
                icon: 'icon-fl-sort-filled',
                name: 'Listings'
            },
            {
                icon: 'icon-fl-heart-filled',
                name: 'Like'
            },
            {
                icon: 'icon-fl-buy',
                name: 'Purchases'
            },
            {
                icon: 'icon-fl-discount',
                name: 'Sales'
            },
            {
                icon: 'icon-fl-logout',
                name: 'Transfer'
            },
            {
                icon: 'icon-fl-star',
                name: 'Burns'
            },
            {
                icon: 'icon-fl-credit-card',
                name: 'Bids'
            },
            {
                icon: 'icon-fl-users-filled',
                name: 'Followings'
            },
        ]
    )

    const [visible , setVisible] = useState(5);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 5);
    }
    return (
        <div>
            <Header />
            <Helmet>
            <title>Activity - CINIVU</title>
            <meta name='description' content="Latest update on new comics, chapters, NFT collectibles. " />
            </Helmet>
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
             
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Activity</li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>
            <section className="tf-activity s1 tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-12">
                            {
                                dataBox.slice(0,visible).map((item,index) =>(
                                    <div className="sc-card-activity style1" key={index}>
                                        <div className="content">
                                            <div className="media">
                                                <img src={APIInfo.API_URL + item.img} alt="Axies" />
                                            </div>
                                            <div className="infor">
                                                <h3> <Link to={item.link}>{item.title}</Link></h3>
                                                <div className="status">{item.status} <span className="author">{item.author}</span></div>
                                                <div className="time">{item.time}</div>
                                            </div>
                                        </div>
                                        <div className={`button-active icon ${item.icon}`}></div>
                                    </div>
                                ))
                            }
                            {
                                visible < dataBox.length && 
                                <div className="col-md-12 wrap-inner load-more text-center"> 
                                    <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3 mt-10" onClick={showMoreItems}><span>Load More</span></Link>
                                </div>
                            }
                        </div>
                        
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Activity01;
