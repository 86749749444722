import React, {useState} from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { APIInfo } from '../config';
import axios from "axios";
import { ClipLoader } from 'react-spinners';
import { Helmet } from 'react-helmet-async';

const Contact02 = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [ responseMessage, setResponseMessage ] = useState(null);
    const [ successMessage, setSuccessMessage ] = useState(null);
    const [ email, setEmail ] = useState(null);
    const [ name, setName ] = useState(null);
    const [ description, setDescription] = useState(null);
    

    const handleEmailChange = e =>{
        setEmail(e.target.value);
    }

    const handleDescriptionChange = e =>{
        setDescription(e.target.value);
    }
    const handleNameChange = e =>{
        setName(e.target.value);
    }

    const contactUs = async() =>{

        if(!name){
            setResponseMessage("Name Field is Required.");
            throw new Error("Name Field is Required.")
        }
        if(!email){
            setResponseMessage("Email Field is Required.");
            throw new Error("Email Field is Required.")
        }
        if(!description){
            setResponseMessage("Message Field is Required.");
            throw new Error("Message Field is Required.")
        }
        setIsLoading(true)
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('description', description);
        
        axios({
            method: 'post',
            responseType: 'json',
            url: APIInfo.API_URL+"/contact-us/api/",
            data: formData,
            headers:{
            "Content-Type": "multipart/form-data",
            }
        })
        .then((response) => {
            
            setSuccessMessage(response.data.message)
            setIsLoading(false)
        })
    }

    return (
        <div>
            <Helmet>
                    <title>CINIVU Contact US</title>
               
                    <meta name='description' content="CINIVU. Kindly fill up the form for all inquiries." />
                       
            </Helmet>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Contact Us</h1>
                            </div>
                            
                        </div>
                    </div>
                </div>                    
            </section>
            <section className="tf-contact tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-12">
                            <div className="flat-form">
                                <h2 className="tf-title-heading ct style-2 mg-bt-12">
                                    Drop Up A Message
                                </h2>
                                {responseMessage && <p className='alert alert-warning'>{responseMessage}</p>}
                                {successMessage && <h4 className='alert alert-success'>{successMessage}</h4>}
                                <div className="form-inner">
                                    <form id="contactform" noValidate="novalidate" onSubmit={()=>contactUs()} >
                                        <input id="name" name="name" tabIndex="1" aria-required="true" required type="text" placeholder="Your Full Name" onChange={e => handleNameChange(e)} />
                                        <input id="email" name="email" tabIndex="2"  aria-required="true" required type="email" placeholder="Your Email Address" onChange={e => handleEmailChange(e)} />
                                        <textarea id="message" name="message" tabIndex="3" aria-required="true" required placeholder="Message" onChange={e => handleDescriptionChange(e)}></textarea>
                                        {!isLoading && <button  type="button" className="submit" onClick={()=>contactUs()}>Send message</button>}
                                        {isLoading && <ClipLoader
                                                    className='mt-1 mb-4'
                                                    color="#fff"
                                                    size={40}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                />}
                                    </form>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Contact02;
