import styled from "styled-components";

const ComicsCafeStyleWrapper = styled.div`
    
    img {
        border-radius: 2%;
    }
    strong.title{
        color:#fdc50f
    }
    
    ul {
        list-style-type: '- ';
      }

      span {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: var(--primary-color4);
    }
    .pointer{font-size:60px;}
    .meta-section{padding:50px;}
    .p-center {
        margin: auto;
        width: 90%;
        padding: 20px 0;
      }
      .cafe-pointer-div{
        width:75%;
        padding: -15px;
        margin: -15px;
      }
      .sub-heading{font-size:20px;}
      .p-left{text-align:left;}
      .p-right{text-align:right;}
      .pointer-img-div{text-align: right;}
      .section-center{display: flex; justify-content: center;margin-top:50px;}
      .flat-title-page {background:#14141f;}
      .sc-box-icon .image .icon-create {
        width: 200px;
        height: 200px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .sc-box-icon .content {
      font-size: 16px;
    }
    .section-title{color:#fdc50f;}
    #id-creator-guild h2{color:#fdc50f;}
    #id-creator-guild highlight{color:#fdc50f;}
    #id-creator-guild .creates a{color:#ffb8c0;}
    #id-creator-guild .artsell a{color:#fbead5;}
    #id-creator-guild .deliver a{color:#04d3e1;}
`;

export default ComicsCafeStyleWrapper;