import React from 'react';
import { Link } from 'react-router-dom'
import deliveryImg from '../../../assets/images/delivery.jpg'
import listImg from '../../../assets/images/creates.png'
import sellImg from '../../../assets/images/artsold.jpg'

const CreativeGuild = () => {
    const data = [
        {
            title: "Digital Artist Creates",
            description: "Artists submits the artwork on the website. Our system generates 500 NFT's of the artwork. Equal number of posters are printed by us and added to our physical inventory.",
            icon : listImg,
            colorbg : "icon-color1",
            headingColor: "creates",
        },
        {
            title: "Collector Buys",
            description: "On every purchase of the poster, our system transfers one nft to the collectors wallet or to our holding wallet. Artists can always check his/her Royalties here or on Solana blockchain.",
            icon : sellImg,
            colorbg : "icon-color2",
            headingColor: "artsell",
        },
        {
            title: "We Deliver",
            description: "Now it's our resposibility to pack the poster in the best packaging available and ship it to the collector's address.",
            icon : deliveryImg,
            colorbg : "icon-color3",
            headingColor: "deliver",
        },
    ]
    return (
        <section id="id-creator-guild" className="tf-box-icon create tf-section bg-home-3">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                            <div className="mg-bt-21 text-center">
                                <h2 className="tf-title pb-18">
                                    Creators Guild
                                </h2>
                                
                            </div>
                        </div>
                    {
                        data.map((item , index) => (
                            <CreateItem key={index} item={item} />
                        ))
                    }
                </div>                 
            </div>
        </section>
    );
}

const CreateItem = props => (
    <div className='col-lg-4 col-md-6 col-12'>
        <div className="sc-box-icon">
        <div className="image center">
            <div className={`icon-create ${props.item.colorbg}`}>
                    <img src={props.item.icon} alt="" />
                </div>                                                                           
            </div>
        <h3 className={`heading ${props.item.headingColor}`}><Link to="/wallet-connect">{props.item.title}</Link></h3>
        <p className={`content ${props.item.headingColor}`}>{props.item.description}</p>
    </div>
    </div>
    
)

export default CreativeGuild;
