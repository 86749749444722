import styled from "styled-components";

const HeaderStyleWrapper = styled.div`
    
    .wallet-adapter-button-trigger{background-color:transparent;border: 1px solid #ccc !important;}
    .wallet-adapter-button-trigger:hover{color:#000 !important;background-color:#ffc413;border-color:#ffc413 !important;}
    .is_dark.sc-button{color:#000 !important;}
    .wallet-adapter-button-trigger img{margin-left:25px;}
    .sc-btn-top .sc-button {
        padding: 12px 15px 12px 5px !important;
    }
    .sc-button.header-slider span, .sc-button.style-place-bid span {
        padding-left: 18px;
        font-size: 15px;
    }
`;

export default HeaderStyleWrapper;