import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Helmet } from 'react-helmet-async';

const AboutUs = () => {
    return (
        <div>
            <Helmet>
                    <title>CINIVU About Us</title>
               
                    <meta name='description' content="CINIVU About Us" />
                       
            </Helmet>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">About Us</h1>
                            </div>
                            
                        </div>
                    </div>
                </div>                    
            </section>
            <section className='tf-section'>
                <div className='row'>
                    <div className='col-8 offset-2'>
                    <p className=''>
                    <h3 className='heading-yellow mt-4 mb-4'>Cinivu - A World of Comics Awaits</h3>
                    <strong>Cinivu:</strong> the NFT Comic Universe! We are excited to bring you a new platform for comics, where you can find a completely fresh set of comics from Cinivu team and others.<br/>
                    Whether you are a writer, designer, or creator, <strong>Cinivu</strong> offers you the tools to come together and collaborate on your own comics. Our platform is built on the Solana blockchain, which allows you to purchase and trade each cast, scene, and page as a unique NFT. These NFTs are stored on a decentralised storage platform called Arweave, ensuring that you can become the proud owner of your favourite scenes from your favourite comics and it’s on blockchain forever.<br/>
                    As a creator, you can even modify the cast members or scenes of your NFTs for future editions. If you can dream it, you can do it with <strong>Cinivu!</strong> And the best part is, you'll earn royalties on each purchase of your comic NFTs. <br/>
                    Learn more about how it works below. We can't wait for you to join us on this exciting journey!<br/>
                    <h3 className='heading-yellow mt-4 mb-4'>Digital Comics - Challenges, how blockchain & metaverse will help.</h3>
                    One of the challenges facing the digital comics market is the issue of downloads and duplication. Digital comics can be easily downloaded and distributed and for the readers the proof of ownership and authenticity of the comics.<br/>
                    Blockchain technology can help to reduce these challenges by providing a secure and transparent way to track ownership and distribution of digital comics. By using blockchain, comics can be tokenized as Non-Fungible Token (NFTs) which are unique digital assets that can't be replicated or replaced. This can help to ensure that creators and publishers are properly compensated for their work and that collectors have verified ownership of their favourite digital comics.<br/>
                    Metaverse can also help community interaction with the creators and collectors through the use of virtual worlds and digital assets. Creators & readers can connect with each other from across the world organise events and games. This can help to build a community around digital comics and create new opportunities for creators and publishers<br/>
                    <h3 className='heading-yellow mt-4 mb-4'>Become Cinivu's Digital Frontiers & Unlock a New Dimension of Storytelling with Cinivu's NFT Comicverse.</h3>
                    - We welcome our Tech-savvy comic fans, digital and crypto art collectors, and anyone interested in digital storytelling.<br/>
                    - For the Fans who wants to dive deeper into the story and become part of it.<br/>
                    - Our customers are looking for an immersive experience that combines the best of digital art and storytelling with the best of blockchain technology. We offer our customers a unique and secure way to collect and trade digital comics, as well as a platform to discover and explore the creative potential of digital art and NFTs.<br/>
                    </p>
                    </div>
                </div>
            </section>
            <section className="tf-contact tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-12">
                            <div className="flat-form">
                                {/* 
                                <h2 className="tf-title-heading ct style-2 mg-bt-12">
                                    Drop Up A Message
                                </h2>
                                <h5 className="sub-title ct style-1 pad-0-15">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.
                                </h5>
                                */}

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default AboutUs;
