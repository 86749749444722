import React , { useState , Fragment, useEffect} from 'react';
import { WalletAdapterNetwork, WalletError, WalletNotConnectedError } from '@solana/wallet-adapter-base';
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom';
import idl from '../idl.json';
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import * as anchor from '@project-serum/anchor';
import {Connection, PublicKey, clusterApiUrl, SystemProgram, Transaction, LAMPORTS_PER_SOL} from '@solana/web3.js';
import { Program, AnchorProvider, utils, web3, BN } from '@project-serum/anchor';
import { NATIVE_MINT, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID, getAssociatedTokenAddress, getAccount, getOrCreateAssociatedTokenAccount, getMint, createSyncNativeInstruction, createAssociatedTokenAccountInstruction } from "@solana/spl-token";
import { APIInfo } from '../config';
import {Buffer} from 'buffer';
import axios from "axios";
import { useAnchorWallet, useWallet } from '@solana/wallet-adapter-react'
import { ReactSession } from 'react-client-session';
import { Metaplex, keypairIdentity, bundlrStorage } from "@metaplex-foundation/js";
import Authors02 from '../pages/Authors02';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

window.Buffer = Buffer;

const programID = new PublicKey(idl.metadata.address);
let program;
let provider;
const network = clusterApiUrl("devnet");
const opts = {
  preflightCommitment: "finalized"
}


const ProfileLoginCheck = ({}) => { 
    const [ responseMessage, setResponseMessage ] = useState(null);
    const [ offers, setOffers ] = useState([]);
    const [ artworks, setArtworks ] = useState([]);
    const [ wishlist, setWishlist ] = useState([]);
    const navigate = useNavigate()
    const connection = new Connection(network, opts.preflightCommitment);
    const {solana} = window;
    const wallet = useWallet()
   
    const [userDict, setUserDict] = useState({scenes:[], menu_tabs: [], panel_tabs: [], })

    useEffect(async() => {
        console.log(wallet.publicKey,"========wallet.publicKey")
        const metaplex = Metaplex.make(connection).use(keypairIdentity(wallet)).use(bundlrStorage());
        const ownerNfts = await metaplex.nfts().findAllByOwner({
            owner: "3tLvCSQubCvCDfpqk3yRLVRqpKymW5LdMmGuavNN4Qk3"
        });
        for(let i=0;i<ownerNfts.length;i++){
            console.log(ownerNfts[0].collection, "--------coll")
        }
        
    }, [])

      useEffect( ()=>{

        async function fetchData() {
            const address = Cookies.get("address");
            
            
            if(address){
                
            const res = await fetch(APIInfo.API_URL+'/story/user/' +address+ '/api/');
            const responseDict = await res.json()
            setUserDict(responseDict)
            setOffers(responseDict['panel_tabs'][2]['dataContent'])
            setWishlist(responseDict['panel_tabs'][1]['dataContent'])
            setArtworks(responseDict['panel_tabs'][0]['dataContent'])
            }
            else{
                navigate("/login")
            }
            
        }
        fetchData();

    }, []);
  

    return (
        <>

            <section className="tf-section authors">
                <div className="themesflat-container  no-padding">
                    <div className="flat-tabs tab-authors">
                        <div className="author-profile flex">
                            {/* 
                            <div className="feature-profile">
                                {userDict.image_url  ?
                                <img alt="Cinivu" className="avatar" />
                                :
                                <img alt="Axies" className="avatar" />
                                }
                            </div>
                            */}
                            <div className="infor-profile col-10">
                                
                                <h2 className="title">{userDict.user_id}</h2>
                                {userDict.description ? 
                                    <p className="content">{userDict.description}</p>
                                    :
                                    <p className="content mt-5">&nbsp;</p>
                                }
                                
                                <form>
                                    <input type="text" className="inputcopy" defaultValue={userDict.wallet_address} readOnly />
                                    <button type="button" className="btn-copycode"><i className="icon-fl-file-1"></i></button>
                                </form>
                            </div>
                            
                            <div className="widget-social style-3">
                                {/* 
                                <ul>
                                    <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                    <li className="style-2"><Link to="#"><i className="fab fa-telegram-plane"></i></Link></li>
                                    <li><Link to="#"><i className="fab fa-youtube"></i></Link></li>
                                    <li className="mgr-none"><Link to="#"><i className="icon-fl-tik-tok-2"></i></Link></li>
                                </ul>
                                */}
                                <div className="btn-profile"><Link to="/account/settings" className="sc-button style-1 follow">Edit Profile</Link></div>
                            </div>
                            
                            
                        </div>
                        {userDict.menu_tabs.length > 0 ?
                            <Tabs>
                                <TabList>
                                    {
                                        userDict.menu_tabs.map((item,index) => (
                                            <Tab key={index}>{item.name}</Tab>
                                        ))
                                    }
                                </TabList>

                                <div className="content-tab">
                                    <div className="content-inner">
                                        <div className="row">
                                            <TabPanel >
                                                {artworks.map((data, index) => (
                                                    <>
                                                        
                                                            <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-12">
                                                                <div className="sc-card-product explode ">
                                                                    <div className="card-media">
                                                                        <img src={APIInfo.API_URL+data.img} alt="Cinivu" />
                                                                        {/* 
                                                                            <div className="button-place-bid ">
                                                                                <button className="sc-button style-place-bid style bag fl-button pri-3"><span>Place Bid</span></button>
                                                                            </div>
                                                                        */}
                                                                        
                                                                    </div>
                                                                    <div className="card-title mg-bt-16">
                                                                    <h5>{data.title}</h5>
                                                                    </div>
                                                                    {/* 
                                                                    {data.price && <div className="card-bottom style-explode">
                                                                        <div className="price">
                                                                            <span>Current Bid</span>
                                                                            <div className="price-details">
                                                                                <h5>{data.price}</h5>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>}
                                                                    */}
                                                                </div>
                                                            </div>
                                                        
                                                        </>
                                                    ))
                                                }
                                            </TabPanel>
                                            <TabPanel >
                                            <div className='container offer-container text-center'>
                                            {wishlist.length>0 ? wishlist.map((data, index) => (
                                                <>
                                                    {data.url &&
                                                        <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-12">
                                                            <div className="sc-card-product explode ">
                                                                <div className="card-media">
                                                                    {data.url && <Link to={data.url}><img src={APIInfo.API_URL+data.img} alt="Cinivu" /></Link>}
                                                                    {/* 
                                                                        <div className="button-place-bid ">
                                                                            <button className="sc-button style-place-bid style bag fl-button pri-3"><span>Place Bid</span></button>
                                                                        </div>
                                                                    */}
                                                                    
                                                                </div>
                                                                <div className="card-title mg-bt-16">
                                                                {data.url && <h5><Link to={data.url}>{data.title}</Link></h5>}
                                                                </div>
                                                                {/* 
                                                                {data.price && <div className="card-bottom style-explode">
                                                                    <div className="price">
                                                                        <span>Current Bid</span>
                                                                        <div className="price-details">
                                                                            <h5>{data.price}</h5>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>}
                                                                */}
                                                            </div>
                                                        </div>
                                                    }
                                                    </>
                                                
                                                ))
                                            
                                            :
                                                    
                                                    <h3 className='text-center'>Inventory is Empty.</h3>
                                            
                                            }
                                            </div>
                                            </TabPanel>

                                            <TabPanel >
                                            <div className='container offer-container text-center'>
                                                {offers.length>0 ?
                                                <>
                                                <div className='row mb-3 mt-2 '>
                                                    <div className='col-2 col-md-2'>
                                                        <h5>Entity</h5>
                                                    </div>
                                                    <div className='col-2 col-md-2'>
                                                        <h5>Address</h5>
                                                    </div>
                                                    <div className='col-2 col-md-2'>
                                                        <h5>Offer Type</h5>
                                                    </div>
                                                    <div className='col-2 col-md-2'>
                                                        <h5>Price</h5>
                                                    </div>
                                                    <div className='col-2 col-md-2'>
                                                        <h5>Offer Date</h5>
                                                    </div>
                                                </div>
                                                <div className='row mb-2'>
                                                    <hr/>
                                                </div>
                                                {offers.length>0 && offers.map((data, index) => (
                                
                                                    <>
                                                        
                                                                    
                                                            <div className='row text-center'>
                                                                <div className='col-2 col-md-2'>
                                                                <Link to={data.url}><img className='offer-img' src={APIInfo.API_URL+data.img} alt="Cinivu" /></Link>
                                                                </div>
                                                                <div className='col-2 col-md-2 mt-4'>
                                                                    <p>You</p> 
                                                                </div>
                                                                
                                                                <div className='col-2 col-md-2 mt-4'>
                                                                    <p>{data.offer_type}</p> 
                                                                </div>
                                                                
                                                                <div className='col-2 col-md-2 mt-4'>
                                                                    <p>{data.price} SOL</p> 
                                                                </div>
                                                                
                                                                <div className='col-2 col-md-2 mt-4'>
                                                                    <p>12th Jan 2023</p> 
                                                                </div>
                                                                
                                                            </div>
                                                            <div className='row'>
                                                            <hr/>
                                                            </div>
                                                            
                                                    </>
                                                ))}
                                                </>
                                                :
                                                    
                                                        <h3 className='text-center'>No Offers To Accept Or Reject.</h3>
                                                    
                                                }
                                            </div>
                                                
                                            
                                            </TabPanel>

                                        </div>
                                    </div>
                                </div>
                            </Tabs>
                            :
                            <div className='loader-div'>
                                <ClipLoader
                                    className='mt-5 mb-4'
                                    color="#fff"
                                    size={100}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </div>
                         }
                    </div>
                </div>
            </section>  
        </>
    );
}



export default ProfileLoginCheck;
