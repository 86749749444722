import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Helmet } from 'react-helmet-async';

const Terms = () => {
    return (
        <div>
            <Helmet>
                    <title>CINIVU Terms of Service</title>
               
                    <meta name='description' content="CINIVU Terms of Service" />
                       
            </Helmet>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Terms</h1>
                            </div>
                            
                        </div>
                    </div>
                </div>                    
            </section>
            <section className='tf-section'>
                <div className='row'>
                    <div className='col-8 offset-2'>
                    <p className=''>
                    CINIVU TERMS AND CONDITIONS:<br/>
                    <br/>
CINIVU NFTs Defined: “CINIVU NFT(s)” refers to a collection of digital artworks running on the Solana blockchain. CINIVU License Agreement: These terms and conditions (“Terms”) define the relationship between (“CINIVU”), and any person (“You”, “User”) interacting with: <br/>
 - Websites owned and operated by CINIVU; <br/>
 - Social media accounts or pages operated by CINIVU; <br/>
 - CINIVU operated smart contracts on the Solana network; or <br/>
 - Any and all CINIVU operated services. Additionally, these terms and conditions define the relationship between CINIVU and any person who maintains ownership of any CINIVU NFTs (“owners”). 
it is important that you read the Terms carefully, as your use of the website will automatically constitute your agreement to be legally bound by the terms and conditions set out herein. In addition to any applicable license agreements on our website, these Terms also govern your use or ordering of the digital and physical products provided through or in connection with the website.
<br/>
<br/>Responsibility of Ownership: <br/>
<br/>
Owners recognize that they are solely responsible for the security of their CINIVU NFTs, as well as the safety, protection, and management of their Solana wallets, which includes any associated passwords, seed phrases, seed words, or private keys. Cinivu, shall not, in any event whatsoever, be held responsible for any risk associated with yours or another’s access and/or use of your Solana wallet whether through the interaction of a CINIVU operated service or otherwise. In the unfortunate event that your CINIVU NFT is lost, stolen, transferred, or otherwise remains inaccessible, CINIVU cannot retrieve it for you.<br/>
<br/>
<br/>Ownership: <br/>
<br/>
By purchasing or lawfully receiving a CINIVU NFT, whether through a CINIVU operated website, or third-party website, and maintaining verifiable ownership of the Solana wallet in which the CINIVU NFT is associated you own the CINIVU NFT, as well as the Art, subject to the definitions and exceptions below.<br/>

Personal Use: Subject to owners continued compliance with these Terms and the below defined Ownership Exceptions, CINIVU grants owners a worldwide, royalty-free license to use, copy, and display the CINIVU NFT owned, along with any extensions they choose to create or use, solely for the following purposes: <br/>

(i) for your own personal, non-commercial use; <br/>
(ii) as part of a marketplace that permits the purchase and sale of your CINIVU NFT, provided that the marketplace cryptographically verifies each CINIVU NFT owner’s rights to display the Art for their CINIVU NFT to ensure that only the actual owner can display the art; or <br/>
(iii) as part of a third party website or application that permits the inclusion, involvement, or participation of your CINIVU NFT, provided that the website/application cryptographically verifies each CINIVU NFT owner’s rights to display the Art for their CINIVU NFT to ensure that only the actual owner can display the art, and provided that the Art is no longer visible once the owner of the CINIVU NFT leaves the website/application.<br/>

Commercial Use: Subject to owners continued compliance with these Terms and the below defined Ownership Exceptions, CINIVU grants owners a worldwide license to use, copy, and display the purchased Art for the purpose of creating derivative works based upon the Art (“Commercial Use”). Examples of such ‘Commercial Use’ would be the use of the Art to produce and sell merchandise products (T-Shirts etc.) displaying copies of the Art. For the sake of clarity, nothing in this Section will be deemed to restrict you from: <br/>
Owning or operating a marketplace that permits the use and sale of CINIVU NFT generally, provided that the marketplace cryptographically verifies each CINIVU NFT owner’s rights to display the Art for their CINIVU NFT to ensure that only the actual owner can display the Art; 
Owning or operating a third party website or application that permits the inclusion, involvement, or participation of CINIVU NFT generally, provided that the third party website or application cryptographically verifies each CINIVU NFT owner’s rights to display the Art for their CINIVU NFT to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased CINIVU NFT leaves the website/application; or <br/>
Earning revenue from any of the foregoing.

       D)   Ownership Exceptions: Both Personal Use and Commercial Use of each CINIVU NFT as defined above are subject to the following exceptions: 
(i) Owners do not own individual traits as defined within the CINIVU NFT metadata e.g. “eyes”, “head”, “hands” etc. or any individual images, layers, or artworks used in the development of CINIVU NFTs; these individual traits may not be used exclusively, added to existing CINIVU NFTs, used to create derivative works, or altered i.e. the owner of the CINIVU NFT artwork owns the CINIVU NFT artwork and only the CINIVU NFT artwork; <br/>
(ii) The use of artwork developed and distributed by CINIVU that are unowned by the individual shall not be used, including but not limited to: logos, marketing material, trademarks, and/or website material; <br/>
(iii) Owners shall not engage in any material, venture, product and/ or service which is unlawful, fraudulent, libellous, defamatory, obscene, pornographic, profane, threatening, abusive, hateful, offensive, objectionable, or unreasonable at the discretion of CINIVU; or <br/>
(iv)Owners shall not infringe upon any intellectual property rights, proprietary rights or confidentiality obligations. Furthermore, owners shall indemnify and defend CINIVU from and against any and all claims, damages, proceedings, loss or costs arising from any such prohibited use. Owners shall not use the CINIVU NFTs in any way that could be construed as being adverse, negative, or derogatory to the image and/or reputation of CINIVU.<br/>



<br/>
Obligations: <br/>
<br/>
As a holder/collector of CINIVU you are solely responsible for your own conduct while accessing or CINIVU’s Site and for any consequences thereof. You agree to use the Site, social medias, and digital assets only for purposes that are legal, proper and in accordance with these Terms and any applicable laws or regulations. By way of example, and not as a limitation, you may not, and may not allow any third party to: <br/>
(i) send, upload, distribute or disseminate any unlawful, defamatory, harassing, abusive, fraudulent, hateful, violent, obscene, or otherwise objectionable content; <br/>
(ii) distribute viruses, worms, defects, Trojan horses, corrupted files, hoaxes, or any other items of a destructive or deceptive nature; <br/>
(iii) impersonate another person; 
(iv) upload, post, transmit or otherwise make available through the Site any content that infringes the intellectual property or proprietary rights of any party or otherwise violates the legal rights of others; <br/>
(v) engage in, promote, or encourage illegal activity (including, without limitation, money laundering); <br/>
(vi) interfere with other users' use of the Site; <br/>
(vii) use the Site for any unauthorized commercial purpose; <br/>
(viii) modify, adapt, translate, or reverse engineer any portion of the Site; <br/>
(ix) remove any copyright, trademark or other proprietary rights notices contained in or on the Site or any part of it; <br/>
(x) use any technology to collect information about the Site’s for any unauthorized purpose; <br/>
(xi) access or use the Site for the purpose of creating a product or service that is competitive with any of our products or services. If you engage in any of the activities prohibited by this Section, we may, at our sole and absolute discretion, without notice to you, and without limiting any of our other rights or remedies at law or in equity, immediately suspend or terminate your user account.<br/>

<br/>
3. Fees and Payment:<br/>
<br/>
A. If you elect to purchase a CINIVU NFT, any financial transactions that you engage in will be conducted solely through the Solana network. We will have no insight into, or control over these payments or transactions. By consequence we do not have the ability to reverse any of these transactions. We will have no liability to you, or to any other third party, for any claims or damages that may arise as the result of any transactions that you engage on, or any other transactions that you conduct via the Solana network. <br/>
B. Solana requires the payment of a transaction fee (a “Gas Fee”) for every transaction that occurs on the Solana network. The ‘Gas Fee’ funds the network of computers that run the decentralized Solana network. This means that you will need to pay a Gas Fee for each transaction.<br/>



<br/>
4. Disclaimers:<br/>
<br/>
A. YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF THE SITE IS AT YOUR SOLE RISK, AND THAT THE SITE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE SITE AND ANY PART OF IT (INCLUDING, WITHOUT LIMITATION, THE SITE, ANY SMART CONTRACT, OR ANY EXTERNAL WEBSITES), INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT:<br/> 
<br/>
(I) YOUR ACCESS TO OR USE OF THE SITE WILL MEET YOUR REQUIREMENTS, <br/>
(II) YOUR ACCESS TO OR USE OF THE SITE WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, <br/>
(III) USAGE DATA PROVIDED THROUGH THE SITE WILL BE ACCURATE, <br/>
(IV) THE SITE OR ANY CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE SITE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR <br/>
(V) THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE SITE WILL BE SECURE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. <br/>
<br/>
B. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND AGREE THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR WILLFUL MISCONDUCT. <br/>
<br/>
C. WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF THE SOLANA NETWORK NOR DO WE HAVE NO CONTROL OVER AND MAKE NO GUARANTEES REGARDING ANY SMART CONTRACTS.<br/>
<br/>

5. Limitation of Liability: <br/>
<br/>
A. YOU UNDERSTAND AND AGREE THAT WE WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.<br/> 

B. YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) ANY PORTION OF THE SITE, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE GREATER OF <br/>
(1) THE AMOUNTS YOU ACTUALLY PAID US UNDER THESE TERMS IN THE 12 MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE, OR 2) $100. <br/>

C. YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE SITE AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN. WE WOULD NOT BE ABLE TO PROVIDE THE SITE TO YOU WITHOUT THESE LIMITATIONS.<br/>

<br/>
6. Risk Assumption: <br/>
<br/>
You accept and acknowledge each of the following: 
A. To the extent that you sell your CINIVU NFT, please be aware that the prices of NFTs are extremely volatile and fluctuations in the prices of other NFTs and impact the price of your CINIVU NFT both positively and negatively. Given the volatility, NFTs such as CINIVU should not be considered an investment. You assume all risks in that connection. 
B. Ownership of a CINIVU NFT confers ownership of digital artwork only. Accordingly, no information on this Site (or any other documents mentioned therein) is or may be considered advice or an invitation to enter into an agreement for any investment purpose. Further, nothing on this Site qualifies or is intended to be an offering of securities in any jurisdiction nor does it constitute an offer or an invitation to purchase shares, securities, or other financial products. Due to the artistic nature of the project, CINIVU has not been registered with or approved by any regulator in any jurisdiction. It remains your sole responsibility to assure that the purchase of the CINIVU NFT and the associated art is compliant with laws and regulations in your jurisdiction. 
C. You assume all risks associated with using an Internet-based currency, including, but not limited to, the risk of hardware, software and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to information stored within your wallet. 
D. NFTs, cryptocurrencies and blockchain technology are relatively new and the regulatory landscape is unsettled. New regulations could negatively impact such technologies impacting the value for your CINIVU NFT. You understand and accept all risk in that regard. 
E. You assume all responsibility for any adverse effects of disruptions or other issues impacting Solana or the Solana platform.


7. Indemnification: 

You agree to hold harmless and indemnify CINIVU and its subsidiaries, affiliates, officers, agents, employees, advertisers, licensors, suppliers or partners from and against any claim, liability, loss, damage (actual and consequential) of any kind or nature, suit, judgment, litigation cost, and reasonable attorneys' fees arising out of or in any way related to 
your breach of these Terms, 
your misuse of the Site, or 
your violation of applicable laws, rules or regulations in connection with your access to or use of the Site.

8. Changes to the Terms and Conditions: 

We may make changes to the Terms at our discretion. Please check these Terms periodically for changes. Any changes to the Terms will apply on the date that they are made, and your continued access to or use after the Terms have been updated will constitute your binding acceptance of the updates. If you do not agree to any revised Terms, you may not access or use the Site.

9. Children:

Our Site is not intended for children, You must be at least 18 years old to access this Site or purchase a CINIVU NFT. If you are under 18 years old, you are not permitted to use this Site for any reason. By accessing the Site, you represent and warrant that you are at least 18 years of age.

10. Dispute Resolution; Arbitration:

WHEN YOU AGREE TO THESE TERMS, YOU ARE AGREEING (EXCEPT AS SPECIFIED IN THE TERMS) TO RESOLVE ANY DISPUTE BETWEEN YOU AND CINIVU THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTION 10 “DISPUTE RESOLUTION” BELOW FOR DETAILS REGARDING ARBITRATION. HOWEVER, IF YOU ARE A RESIDENT OF A JURISDICTION WHERE APPLICABLE LAW PROHIBITS ARBITRATION OF DISPUTES, THE AGREEMENT TO ARBITRATE IN SECTION 10 WILL NOT APPLY TO YOU BUT THE PROVISIONS OF SECTION 9 “GOVERNING LAW” OR RELEVANT PROVISIONS OF APPLICABLE LAW WILL STILL APPLY. FOR THE AVOIDANCE OF DOUBT, NOTHING IN THIS AGREEMENT SHALL BE CONSTRUED AS AN OBLIGATION ON CINIVU TO CREATE / PROVIDE ANY ACCESS RIGHTS IN RELATION TO THE NFT.. The award of the arbitrator will be final and binding, and any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. Notwithstanding the foregoing, we may seek and obtain injunctive relief in any jurisdiction in any court of competent jurisdiction. WITH RESPECT TO ANY DISPUTE ARISING OUT OF OR RELATED TO THESE TERMS, INCLUDING WITHOUT LIMITATION DISPUTES RELATED TO THE SITE OR ANY PRODUCTS SOLD OR DISTRIBUTED THROUGH THE SITE, OR THE SMART CONTRACTS: 
YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO HAVE A TRIAL BY JURY; AND 
(II) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN ANY LAWSUIT, INCLUDING BUT NOT LIMITED TO CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE.

                    </p>
                    </div>
                </div>
            </section>
            <section className="tf-contact tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-12">
                            <div className="flat-form">
                                {/* 
                                <h2 className="tf-title-heading ct style-2 mg-bt-12">
                                    Drop Up A Message
                                </h2>
                                <h5 className="sub-title ct style-1 pad-0-15">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.
                                </h5>
                                */}

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Terms;
