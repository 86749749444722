import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import avt from '../assets/images/avatar/avata_profile.jpg'
import bg1 from '../assets/images/backgroup-secsion/option1_bg_profile.jpg'
import bg2 from '../assets/images/backgroup-secsion/option2_bg_profile.jpg'
import { APIInfo } from '../config';
import axios from 'axios';
import WalletContextProvider from '../contexts/WalletContextProvider';
import EditProfileStyleWrapper from './account/EditProfile.style';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { ClipLoader } from 'react-spinners';

const EditProfile = () => {

    const [ email, setEmail ] = useState(null);
    const [ name, setName ] = useState(null);
    const [ description, setDescription] = useState(null);
    const [ responseDict, setresponseDict] = useState(null);
    const [ successMessage, setSuccessMessage ] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [ userDict, setUserDict] = useState({});
    const [ address, setAddress] = useState({});
    const wallet = useWallet()
    const updateProfile = async(e) =>{
        e.preventDefault();
        setIsLoading(true)
        const formData = new FormData();
        formData.append('address', wallet.publicKey.toString());
        if(name){
            formData.append('name', name);
        }
        if(email){
            formData.append('email', email);
        }
        if(description){
            formData.append('description', description);
        }
        
        axios({
            method: 'post',
            responseType: 'json',
            url: APIInfo.API_URL+"/user/edit/api/",
            data: formData,
            headers:{
            "Content-Type": "multipart/form-data",
            }
        })
        .then((response) => {
            setSuccessMessage("Profile Updated.")
            setIsLoading(false)
        })

    }
    const handleEmailChange = e =>{
        setEmail(e.target.value);
    }

    const handleDescriptionChange = e =>{
        setDescription(e.target.value);
    }
    const handleNameChange = e =>{
        setName(e.target.value);
    }
    useEffect(() => {
        async function fetchData() {
            const formData = new FormData();
            formData.append('address', wallet.publicKey.toString());
            
            axios({
                method: 'post',
                responseType: 'json',
                url: APIInfo.API_URL+"/user/profile/api/",
                data: formData,
                headers:{
                "Content-Type": "multipart/form-data",
                }
            })
            .then((response) => {
                
              setUserDict(response.data)
            })
            
        }
    fetchData();
    }, [wallet.connected]);
    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Your Preferences</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/profile">Profile</Link></li>
                        
                                    <li>Edit Details</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>
            <div className="tf-create-item tf-section">
                <div className="themesflat-container">
                    <EditProfileStyleWrapper>
                        
                            
                            {wallet.connected ? 
                                <>
                                    <div className="row">
                                        {/* 
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                            <div className="sc-card-profile text-center">
                                                <div className="card-media">
                                                    <img id="profileimg" src={avt} alt="Axies" />                         
                                                </div>
                                            <div id="upload-profile">
                                                <Link to="#" className="btn-upload">
                                                    Upload New Photo </Link>
                                                    <input id="tf-upload-img" type="file" name="profile" required="" />
                                            </div>
                                            <Link to="#" className="btn-upload style2">
                                                Delete</Link>
                                            </div>
                                        </div>
                                        */}
                                        <div className="col-xl-9 col-lg-8 col-md-12 col-12 offset-1">
                                            <div className="form-upload-profile">
                                            
                                                {/* 
                                                <h4 className="title-create-item">Choice your Cover image</h4>
                                                <div className="option-profile clearfix">
                                                    <form action="#">
                                                        <label className="uploadFile">
                                                            <input type="file" className="inputfile form-control" name="file" />
                                                        </label>
                                                    </form>
                                                    <div className="image">
                                                        <img src={bg1} alt="Axies" />
                                                    </div>
                                                    <div className="image style2">
                                                        <img src={bg2} alt="Axies" />
                                                    </div>
                                                </div>
                                                */}
                                                <form action="#" className="form-profile">
                                                    <div className="form-infor-profile">
                                                        <div className="info-account">
                                                            <h4 className="title-create-item">Account info</h4>  
                                                                                              
                                                                <fieldset>
                                                                    <h4 className="title-infor-account">Display name</h4>
                                                                    <input value={userDict.name} name="name" type="text" required onChange={e => handleNameChange(e)}/>
                                                                </fieldset>
                                                        
                                                            
                                                                <fieldset>
                                                                    <h4 className="title-infor-account">Bio</h4>
                                                                    <textarea tabIndex="4" rows="5" required  onChange={e => handleDescriptionChange(e)}>
                                                                        {userDict.description}
                                                                    </textarea>
                                                                </fieldset> 
                                                        </div>
                                                        <div className="info-account">
                                                            <h4 className="title-create-item">&nbsp;</h4>                                    
                                                                <fieldset>
                                                                    <h4 className="title-infor-account">Email</h4>
                                                                    <input value={userDict.email} name="email" type="text" required onChange={e => handleEmailChange(e)}/>
                                                                    {/*<Link to="#" className="connect"><i className="fab fa-facebook"></i>Connect to face book</Link>*/}
                                                                </fieldset>
                                                                
                                                                
                                                        </div> 
                                                    </div>
                                                    <div className='row'>
                                                    <div className='col-6'>
                                                    {successMessage && <h5 className='alert alert-success'>{successMessage}</h5>}
                                                    </div>
                                                    </div>
                                                    {!isLoading && <button className="tf-button-submit mg-t-15" type="submit" onClick={updateProfile}>
                                                        Update Profile
                                                    </button>}  
                                                    {isLoading && <ClipLoader
                                                    className='mt-1 mb-4'
                                                    color="#fff"
                                                    size={40}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                    />}         
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <div className="container">
                                                                <h2 className="tf-title-heading ct style-1">
                                Login To Your Account
                            </h2>
                                                                <div className="flat-form box-login-social">
                                <div className="box-title-login">
                                    <h5>Connect with your phantom wallet</h5>
                                </div>

                            </div>
                                    <div className='login-div mt-5 mb-5'>
                                    <WalletMultiButton />
                                    </div>
                                </div>
                            }   
                        
                    </EditProfileStyleWrapper>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default EditProfile;
