import styled from "styled-components";
import bg from '../../assets/images/item-background/bgprofile.jpg'

const EditProfileStyleWrapper = styled.div`
    
    .bid-history-list{
        max-height:350px;
        overflow-x: hidden;
        overflow-y: auto;}
    hr {
        margin-bottom: 15px;

        border:1px dashed rgb(65, 65, 69);
    }
    .offer-img{max-width:100px;max-height:150px;}
    .offer-container{
        background: #343444;
        padding: 15px;
        border-radius: 5px;
    }
    .offer-head{
        background: #ccc;
    }
    @media (max-width: 400px) {
        .author-infor p{font-size:11px !important;}
        .sc-button{padding:6px !important;}
        .breadcrumbs{text-align:center;}
    }
    .wallet-adapter-button-trigger{background-color:transparent;border: 1px solid #ccc;}
    .wallet-adapter-button-trigger:hover{color:#000;background-color:#ffc413;}
    .tab-authors .author-profile{background:url(${bg}) no-repeat center;}
    .no-padding{padding:0px !important;border-radius:0px;}
    .react-tabs__tab-list{padding:0 30% 0 2.5% !important;}
    .card-media{height:400px;max-width:300px;}
    .loader-div{
        justify-content: center;
        display: flex;
    }
`;

export default EditProfileStyleWrapper;